<template>
  <v-btn
    id="account-btn"
    v-testid="'account-btn'"
    :loading="isLoading"
    fab
    small
    color="primary"
    @click="$emit('init')"
  >
    {{ firstLetters }}
  </v-btn>
</template>

<script>
import { useAuth } from '@/modules/auth/api';
export default {
  name: 'AvatarButton',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { firstLetters } = useAuth();

    return {
      firstLetters
    };
  }
};
</script>
