<template>
  <div
    v-if="!$vuetify.breakpoint.mobile"
    class="d-flex right-top-toolbar mr-5 mt-3"
  >
    <time-widget v-if="!!showTime" />
    <notifications />
    <app-select />

    <v-menu v-model="isShowMenu" offset-y nudge-width="300" :nudge-bottom="12">
      <template v-slot:activator="{ on }">
        <account-button
          :is-loading="isLoadingProfiles"
          v-on="on"
          @init="handleClick"
        />
      </template>
      <account-menu />
    </v-menu>
  </div>
</template>
<script>
import AccountMenu from './AccountMenu';
import AccountButton from './AccountButton';
import Notifications from '@/layouts/map/headerMenu/notifications/index';
import AppSelect from '@/layouts/map/headerMenu/AppSelect';
import TimeWidget from './TimeWidget.vue';
import { useProgramSettings } from '@/modules/account/useProgramSettings';
import { computed, ref } from '@vue/composition-api';
import { useAuth } from '@/modules/auth/api';
export default {
  name: 'AppRightTopToolbar',
  components: {
    AppSelect,
    Notifications,
    AccountMenu,
    AccountButton,
    TimeWidget
  },
  setup() {
    const { programSettings } = useProgramSettings();
    const { loadNotificationProfiles, isLoadingProfiles } = useAuth();
    const isShowMenu = ref(false);

    const handleClick = async e => {
      if (!isShowMenu.value) {
        await loadNotificationProfiles();
        isShowMenu.value = true;
      } else {
        isShowMenu.value = false;
      }
    };

    return {
      showTime: computed(
        () => programSettings.value?.programShowCoreTime?.value
      ),
      isShowMenu,
      isLoadingProfiles,
      handleClick
    };
  }
};
</script>

<style lang="sass" scoped>
.right-top-toolbar
    position: absolute
    right: 0
    top: 0
</style>
