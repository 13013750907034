<template>
  <div class="timeWidget elevation-4 mr-3 px-3">
    <v-icon class="icon" v-text="'$time'" />
    <span class="ml-2 time" :class="{ hidden: loading }">
      <span>{{ `${hours}:${minutes}` }} ({{ timezone }})</span>
    </span>
  </div>
</template>

<script>
import {
  computed,
  onBeforeUnmount,
  onMounted,
  ref
} from '@vue/composition-api';
import { getTimezoneShift } from '@/modules/about/getVersion';
import { getDateWithTimeZone } from '@/utils';

export default {
  name: 'TimeWidget',
  setup() {
    let timer = null;
    const date = ref(getDateWithTimeZone(0));
    const loading = ref(true);
    const timezone = ref('');

    const setDate = timezone => {
      date.value = getDateWithTimeZone(timezone);
    };

    onMounted(async () => {
      let timezoneShift = 0;

      try {
        const data = await getTimezoneShift();
        timezoneShift = data.getTimezoneShift;
        timezone.value = data.getTimezone;
      } finally {
        loading.value = false;
      }

      setDate(timezoneShift);

      timer = setInterval(() => {
        setDate(timezoneShift);
      }, 1000);
    });
    onBeforeUnmount(() => {
      timer && clearInterval(timer);
    });

    return {
      date,
      loading,
      timezone,
      hours: computed(() =>
        date.value.getUTCHours() < 10
          ? `0${date.value.getUTCHours()}`
          : date.value.getUTCHours()
      ),
      minutes: computed(() =>
        date.value.getUTCMinutes() < 10
          ? `0${date.value.getUTCMinutes()}`
          : date.value.getUTCMinutes()
      )
    };
  }
};
</script>

<style lang="scss">
.timeWidget {
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 1000px;
  background-color: #ffffff;
}
.icon {
  color: inherit !important;
}
.time {
  line-height: initial !important;
  font-size: 16px !important;
  font-weight: 600;
}

.hidden {
  visibility: hidden;
}
</style>
